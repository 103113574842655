

































import type { Link } from '@/inc/types'
import { defineComponent, PropType } from '@vue/composition-api'

import FlexibleSlider from '@/components/flexible/Slider.vue'
import AWMood, { Mood } from '@/components/anders-wonen/Mood.vue'

interface AWMoodSlider {
  title: string
  htmltext: string
  items: Mood[]
  link?: Link
}

export default defineComponent({
  name: 'AWMoodSlider',
  components: {
    FlexibleSlider,
    AWMood,
  },
  props: {
    content: {
      type: Object as PropType<AWMoodSlider>,
      required: true,
    },
  },

  setup() {
    return {}
  },
})
